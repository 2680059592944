function insertExerciseData(data, target) {
    let videoNL = data.video_nl.split("/");
    videoNL = videoNL[videoNL.length - 1];
    let videoEN = data.video_en.split("/");
    videoEN = videoEN[videoEN.length - 1];
    $(`#${target}Modal input[name='video_nl']`).val(`https://www.youtube.com/watch?v=${videoNL}`);
    $(`#${target}Modal input[name='video_en']`).val(`https://www.youtube.com/watch?v=${videoEN}`);
    $(`#${target}Modal input[name='loops']`).val(data.loops);
    $(`#${target}Modal input[name='sets']`).val(data.sets);
    $(`#${target}Modal input[name='rest']`).val(data.rest);
    $(`#${target}Modal input[name='frequency']`).val(data.frequency);
    $(`#${target}Modal input[name='start_pos_nl']`).val(data.start_pos_nl);
    $(`#${target}Modal input[name='start_pos_en']`).val(data.start_pos_en);
    $(`#${target}Modal textarea[name='execution_nl']`).val(data.execution_nl);
    $(`#${target}Modal textarea[name='execution_en']`).val(data.execution_en);
    $(".copied-attribute").remove();
    for (let x = 0; x < data.materials.length; x += 1) {
        const title = `${data.materials[x].name_nl} ${data.materials[x].id}1`;
        const slug = title
            .replace(/\s+/g, "-")
            .replace(/['(']|[')']/g, "")
            .toLowerCase();
        $(`#${slug}`).prop("checked", true);
    }

    // Will only run if there is more then 1 attribute
    window.setAttributes(data);
}
window.insertExerciseData = insertExerciseData;

function resetFormExercise(target, update = false) {
    $(`#${target}Modal form`).removeClass(`was-validated`);
    $(`#${target}Modal input[name='id']`).val(``);
    $(`#${target}Modal input[name='title_nl']`).val(``);
    $(`#${target}Modal input[name='title_en']`).val(``);
    $(`#${target}Modal textarea[name='description_nl']`).val(``);
    $(`#${target}Modal textarea[name='description_en']`).val(``);

    $(`#${target}Modal input[name='video_nl']`).val(``);
    $(`#${target}Modal input[name='video_en']`).val(``);
    $(`#${target}Modal input[name='loops']`).val(``);
    $(`#${target}Modal input[name='sets']`).val(``);
    $(`#${target}Modal input[name='rest']`).val(``);
    $(`#${target}Modal input[name='frequency']`).val(``);
    $(`#${target}Modal input[name='start_pos_nl']`).val(``);
    $(`#${target}Modal input[name='start_pos_en']`).val(``);
    $(`#${target}Modal textarea[name='execution_nl']`).val();
    $(`#${target}Modal textarea[name='execution_en']`).val(``);

    $("#exercise_id_hidden").empty();
    if (update) {
        $("#exercise_id_hidden").append('<input type="hidden" name="id"> --}}');
    }

    if ($(`#english_button`).data("show") === false) window.toggleEnglish();

    // Attributes
    const copy = $(`#attribute-wrapper-form .attribute-section[data-section=0]`).clone();
    copy.children(`.attribute-option`).hide();
    copy.show();
    $(`.attribute-section`).remove();
    $(`#attribute-wrapper-form`).append(copy);
}
window.resetFormExercise = resetFormExercise;

/*
 *  Searches in the given array for all corresponding data-tags
 *  Expects the wrapper to be a id and the searchArray the array with all search values
 *  The data tags need to be in the format data-search-{{key}}
 *  The following searchTypes are available :
 *      exact:  value
 *      range:  min, max
 *      string: value
 *      bool: true or false
 *
 */
function searchExercises(selectElement, key, wrapperId, searchArray, searchType) {
    if (typeof window[searchArray] === "undefined") {
        window[searchArray] = {};
    }

    if ($(selectElement).val() === "" && (searchType === "exact" || searchType === "string")) {
        delete window[searchArray][key];
    } else if (searchType === "bool") {
        window[searchArray][key] = { value: $(selectElement).is(":checked"), type: searchType };
    } else if (searchType === "exact" || searchType === "string") {
        window[searchArray][key] = { value: $(selectElement).val(), type: searchType };
    } else if (searchType === "range") {
        const t = selectElement.noUiSlider.get();
        window[searchArray][key] = { min: t[0], max: t[1], type: searchType };
    }

    // Actually search
    // Loop through all children
    const children = $(`#${wrapperId}`).children("a,div");

    for (let i = 0; i < children.length; i += 1) {
        // If no search terms are set, show all elements
        if (Object.keys(window[searchArray]).length === 0) {
            $(children[i]).show();
        }

        // Foreach key check if the given child has the values
        $(children[i]).removeClass("hide-important");
        Object.keys(window[searchArray]).forEach(keyI => {
            // The value of the element needs to be exactly the given value
            if (window[searchArray][keyI].type === "exact") {
                const searchVal = window[searchArray][keyI].value;
                const elementVal = $(children[i]).attr(`data-search-${keyI.toLowerCase()}`);
                if (elementVal === undefined) {
                    $(children[i]).addClass("hide-important");
                } else if (elementVal.includes(searchVal)) {
                    // $(children[i]).show();
                } else {
                    $(children[i]).addClass("hide-important");
                }
            } else if (window[searchArray][keyI].type === "range") {
                // The value of the element needs to be in between the given min and max
                const min = parseInt(window[searchArray][keyI].min);
                const max = parseInt(window[searchArray][keyI].max);
                const elementVal = $(children[i]).attr(`data-search-${keyI.toLowerCase()}`);
                if (max >= elementVal && elementVal >= min) {
                    // $(children[i]).show();
                } else {
                    $(children[i]).addClass("hide-important");
                }
            } else if (window[searchArray][keyI].type === "string") {
                // The value of the element needs to contain the given value
                const { value } = window[searchArray][keyI];
                const elementVal = $(children[i]).attr(`data-search-${keyI.toLowerCase()}`);
                if (elementVal.toLowerCase().indexOf(value.toLowerCase()) === -1) {
                    $(children[i]).addClass("hide-important");
                } else {
                    // $(children[i]).show();
                }
            } else if (window[searchArray][keyI].type === "bool") {
                // The value needs to be equal or not equal
                const boolValue = window[searchArray][keyI].value;
                const elementVal = $(children[i]).attr(`data-search-${keyI.toLowerCase()}`);
                if (elementVal.includes(boolValue)) {
                    // $(children[i]).show();
                } else {
                    $(children[i]).addClass("hide-important");
                }
            }
        });
    }
}
window.searchExercises = searchExercises;

/*
 * When the DOM with all its elements are loaded.
 */
$(function () {
    /**
     *  Remove any hidden or disable options so that the request can be processed normaly
     */
    $("#exercise_form_submit").submit(_ => {
        $("[class*=attribute-option-]:hidden").remove();
    });

    /*
     * When you click on the thumbnail of the video, the video is loaded in and the thumbnail is substituted with the video.
     */
    $(".vid-th").on("click", function () {
        const exerciseVideo = $(this).siblings(".vid-url").val();
        const aspr = document.createElement("div");
        const ifrm = document.createElement("iframe");
        const vidsrc = `${exerciseVideo}?rel=0&autoplay=1&showinfo=0`;
        const dataId = $(this).siblings(".data-id").val();
        $(this).siblings("i").remove();

        $(aspr).attr({
            class: "embed-responsive embed-responsive-16by9 d-flex justify-content-center",
            id: `iframeContainer${dataId}`
        });

        $(ifrm).attr({
            src: vidsrc,
            allowfullscreen: "",
            id: `exvi${dataId}`,
            width: "50%",
            height: "300px",
            class: "shadow"
        });

        let elem = 0;
        if ($(this).is("img")) {
            elem = $(this);
        } else if ($(this).is("i")) {
            elem = $(this).siblings("img");
        }

        $(elem).addClass("d-none");
        $(elem).parent(".mediacontainer").append(aspr);
        $(elem).siblings(".embed-responsive").append(ifrm);

        if ($(this).is("i")) {
            $(this).remove();
        }
        $(elem).parent(".mediacontainer").removeClass("mediacontainer");
    });
});
